//
//
//
//
//
//
//
//
//

export default {
    props: {
        localeCode: {
            type: String,
            required: true
        }
    }
}
