//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { flag } from 'country-emoji';
import FlagIcon from '../components/icons/FlagIcon.vue';

export default {
    components: {
        FlagIcon
    },
    data(){
        return {
            selectIsOpen: false,
            selected: null,
            tabindex: 0
        }
    },
    methods: {
        // Seteamos el nuevo lenguaje
        setLanguage(code){
            this.closeSelect()
            this.$language.setLanguage(code);
        },
        isSelected(code){
            return this.$i18n.locale === code;
        },
        getLocaleEmoji(code){
            return (code !== 'en') ? flag(code.toUpperCase()) : flag('GB');
        },
        closeSelect(){
            this.selectIsOpen = false;
        },
        toggleSelect(){
            this.selectIsOpen = !this.selectIsOpen;
        }
    },
    computed: {
        availableLocales () {
            return this.$i18n.locales
        },
        selectedLanguage(){
            return this.$i18n.locale;
        }
    }
}
